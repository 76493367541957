import * as React from "react"
import { graphql, Link } from "gatsby"
import { PrismicRichText } from '@prismicio/react'
import { GatsbyImage } from 'gatsby-plugin-image'
import { linkResolver } from '../../../utils/link_resolver'
import Seo from '../../../components/seo'
import Footer from '../../../components/footer'
import Header from '../../../components/header'
import HelperMaterialSign from '../../../components/helper_material_sign'
import CartContext from '../../../components/cart_context';
import ProductQuantity from '../../../components/product_quantity';
import { useState, useContext, useEffect } from 'react'
import ProductSafety from "../../../components/product_safety"
import ProductHeader from "../../../components/product_header"
import ProductCustomHeader from "../../../components/product_custom_header"
import Breadcrumb from "../../../components/breadcrumb"

const ChristmasDecorations = ({data}) => {  
  const entry = data.prismicXmasDecorations

  // needs error callbacks
  const {getProductById} = useContext(CartContext);
  
  const [product, setProduct] = useState(null);
  const [selectedVariant, setSelectedVariant] = useState(null);
  const [variantId, setVariantId] = useState(null);
  
  const [selectedNumber, setSelectedNumber] = useState(data.shopifyProduct.options[0].values[0])
  //console.log('selectedNumber:', selectedNumber);

  const [selectedShape, setSelectedShape] = useState(entry.data.shape[0].name)
  //console.log('selectedShape:', selectedShape);
  const [moreGraphics, setMoreGraphics] = useState(false)

  let colour = getStockLevels(entry.data.colour, 'colour');
  //console.log('colour:', colour);
  const [selectedColour, setSelectedColour] = useState(colour[0].name)
  const [selectedColourImage, setSelectedColourImage] = useState(colour[0])
  
  //console.log('selectedColour:', selectedColour);

  const toggleMoreGraphics = () => {
    setMoreGraphics(!moreGraphics);
  };

  const [formValues, setFormValues] = useState({
    formErrors: '',
    formIsValid: true,
  });

  const [customSelections, setCustomSelections] = useState([
    {
      key: "Shape",
      value: selectedShape
    },
    {
      key: "Colour",
      value: selectedColour
    },
    {
      key: "Number",
      value: selectedNumber
    }
  ])
  
  //console.log('customSelections: ', customSelections)

  // data.shopifyProduct.storefrontId
  // data.shopifyProduct.shopifyId

  useEffect(() => {
    //console.log('data.shopifyProduct.shopifyId: ', data.shopifyProduct);
    getProductById(data.shopifyProduct.shopifyId).then(result => {
      setProduct(result);
      if (result) {
        setSelectedVariant(
          result.variants.find(({ id }) => id === variantId) || result.variants[0]
        );
      }
    });
  }, [
    getProductById,
    setProduct,
    data.shopifyProduct.shopifyId,
    variantId,
    selectedShape,
    selectedColour,
    selectedColourImage,
    selectedNumber,
    customSelections,
  ]);

  function getStockLevels(data, component) {
    const newStock = data.filter(colour => {
      return !entry.data.out_of_stock.some(stock => {
        if (stock.component === component) {
          return stock.name === colour.name;
        }
        return false;
      });
    });
    return newStock;
  }

  const handleVariantChange = e => {
    setSelectedNumber(e.target.value);
    let selectedVariantChange = `${e.target.value}`;

    let newVariant = data.shopifyProduct?.variants.find(
      variant => variant.title === selectedVariantChange
    );
    setVariantId(newVariant.shopifyId);

    const newCustomSelections = customSelections.map(customSelection => {
      if (customSelection.key === 'Number') {
        return {...customSelection, value: e.target.value};
      }
      return customSelection;
    });
    setCustomSelections(newCustomSelections)
  };

  const handleColourChange = e => {
    setSelectedColour(e.target.value);
    const newCustomSelections = customSelections.map(customSelection => {
      if (customSelection.key === 'Colour') {
        return {...customSelection, value: e.target.value};
      }
      return customSelection;
    });
    setCustomSelections(newCustomSelections)
    const newSelectedColourImage = entry.data.colour.find(image => (
      image.name === e.target.value
    ))
    setSelectedColourImage(newSelectedColourImage)
  };

  const handleShapeChange = e => {
    setSelectedShape(e.target.value);
    const newCustomSelections = customSelections.map(customSelection => {
      if (customSelection.key === 'Shape') {
        return {...customSelection, value: e.target.value};
      }
      return customSelection;
    });
    setCustomSelections(newCustomSelections)
  };

  const currency = new Intl.NumberFormat('en-NZ', {
    style: 'currency',
    currency: 'NZD',
    minimumFractionDigits: 2
  })

  if (!entry) return null
  return (
    <>
      <Seo title={entry.data.page_title} description={entry.data.meta_description} image="" pathname={linkResolver(entry.data)} />
      <Header />
      <Breadcrumb 
        category="Signs" 
        category_url="signs"
        subcategory="" 
        subcategory_url=""
        product={entry.data.heading} 
      />
      <ProductHeader data={entry.data} />
      <ProductCustomHeader props="Customise Yours" />

      <div className="w-full pb-16">
        <div className="w-10/12 mx-auto max-w-screen-xl">
          <div className="flex-none md:flex justify-between gap-12">
            <div className="w-full md:w-2/12">
              <div className="bg-diamond rounded-full w-36 h-36 flex items-center justify-center">
                <img src="/img/ethos_white.png" className="w-28 h-28" alt="Number" />
              </div>
            </div>
            <div className="w-full md:w-10/12 pt-6 md:pt-0">
              <div className="flex-none md:flex items-start justify-between pb-6">
                <div className="w-full md:w-10/12">
                  <div>
                    <h2 className="font-poppins font-semibold text-2xl md:text-3xl text-orange mb-3">Choose shape</h2>
                    <div className="max-w-none prose font-poppins font-medium prose-p:text-diamond-80 leading-snug">
                      <PrismicRichText linkResolver={linkResolver} field={entry.data.shape_text.richText} />
                    </div>
                  </div>
                </div>
                <div className="w-full md:w-2/12 mt-3 sm:mt-0 text-left md:text-right">
                  {moreGraphics ?
                  <button onClick={toggleMoreGraphics} className="font-poppins bg-pink px-2 py-1 font-semibold text-white inline-block">
                    See less &#62;
                  </button>
                  : 
                  <button onClick={toggleMoreGraphics} className="font-poppins bg-purple px-2 py-1 font-semibold text-white inline-block">
                    See all &#62;
                  </button>
                  }
                </div>
              </div>
              {!!selectedVariant && 
                <div className="w-full md:w-10/12">
                  <div className="sr-only">Choose shape</div>
                  <div className="grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-4 gap-6 text-left ">
                    {entry.data.shape.map((custom,i) => (
                      (i < 12 || moreGraphics) && (
                      <label htmlFor={`shape_${custom.name}`} key={i} className={custom.name === selectedShape ? 'flex items-center px-2 py-1 rounded-xl border-2 border-diamond cursor-pointer' : 'flex items-center px-2 py-1 border-2 border-transparent cursor-pointer'}>
                        <input
                          id={`shape_${custom.name}`}
                          defaultChecked={custom.name === selectedShape}
                          type="radio"
                          value={custom.name}
                          onChange={handleShapeChange}
                          name="shape"
                          className="hidden"
                        />
                        <GatsbyImage className="object-contain block" image={custom.image.gatsbyImageData} alt={custom.image.alt ?? ''} />
                      </label>
                      )
                    ))}
                  </div>
                </div>
              }
            </div>
          </div>
        </div>
      </div>

      <div className="w-full pb-16">
        <div className="w-10/12 mx-auto max-w-screen-xl">
          <div className="flex-none md:flex justify-between gap-12">
            <div className="w-full md:w-2/12">
              <div className="bg-diamond rounded-full w-36 h-36 flex items-center justify-center">
                <img src="/img/ethos_white.png" className="w-28 h-28" alt="Number" />
              </div>
            </div>
            <div className="w-full md:w-10/12 pt-6 md:pt-0">
              <div className="flex-none md:flex items-center justify-between pb-6">
                <div className="w-full md:w-8/12">
                  <h2 className="font-poppins font-semibold text-2xl md:text-3xl text-orange">Choose colour</h2>
                </div>
                <div className="w-full md:w-4/12 text-left md:text-right">
                  <HelperMaterialSign name="About material" data={entry.data} />
                </div>
              </div>
              {!!selectedVariant && 
                <div className="flex justify-between gap-6">
                  <div className="sr-only">Choose colour</div>
                  <div className="w-6/12 md:w-9/12">
                    <div className="grid grid-cols-1 md:grid-cols-3 gap-6 text-left">
                      {colour.map((custom,i) => (
                        <label htmlFor={`colour_${custom.name}`} key={i} className="text-sm md:text-base flex items-center gap-3 cursor-pointer font-poppins font-medium text-diamond-80">
                          <input
                            id={`colour_${custom.name}`}
                            checked={custom.name === selectedColour}
                            type="radio"
                            value={custom.name}
                            onChange={handleColourChange}
                            name="colour"
                            style={{backgroundColor: `${custom.hex_code}`}}
                            className={custom.name === selectedColour ? 'w-5 h-5 text-orange border-none ring-diamond-80 ring-2 ring-offset-2 focus:outine-none focus:ring-diamond-80 focus:outline-offset-0' : 'w-5 h-5 bg-diamond-10 text-orange border-none focus:outine-none focus:ring-diamond-80 focus:outline-offset-0'}
                          />
                          {custom.name}
                        </label>
                      ))}
                    </div>
                  </div>
                  <div className="w-6/12 md:w-3/12">
                    <GatsbyImage className="object-fit w-full block" image={selectedColourImage.image.gatsbyImageData} alt={selectedColourImage.image.alt ?? ''} />
                  </div>
                </div>
              }
            </div>
          </div>
        </div>
      </div>

      <div className="w-full pb-12">
        <div className="w-10/12 mx-auto max-w-screen-xl">
          <div className="flex-none md:flex justify-between gap-12">
            <div className="w-full md:w-2/12">
              <div className="bg-diamond rounded-full w-36 h-36 flex items-center justify-center">
                <img src="/img/size_duo_white.png" className="w-24 h-24" alt="" />
              </div>
            </div>
            <div className="w-full md:w-10/12 pt-6 md:pt-0">
              <div className="flex-none md:flex items-center justify-between pb-6">
                <div>
                  <h2 className="font-poppins font-semibold text-2xl md:text-3xl text-orange mb-3">Choose number</h2>
                  <div className="max-w-none prose font-poppins prose-p:text-diamond-80 prose-h2:text-2xl prose-h2:text-3xl prose-h2:font-semibold prose-h2:text-orange prose-h2:mb-3 prose-p:font-medium leading-snug mb-3">
                    <PrismicRichText linkResolver={linkResolver} field={entry.data.number_text.richText} />
                  </div>
                </div>
              </div>
              {!!selectedVariant && 
                <div>
                  <div className="sr-only">
                    Choose number
                  </div>
                  <div className="w-full">
                    <div className="grid grid-cols-1 md:grid-cols-3 gap-6 text-left">
                      {data.shopifyProduct.options[0].values.map((name,i) => (
                        <label htmlFor={`number_${name}`} key={i} className="text-sm md:text-base flex items-center gap-3 cursor-pointer font-poppins font-medium text-diamond-80">
                          <input
                            id={`number_${name}`}
                            checked={name === selectedNumber}
                            type="radio"
                            value={name}
                            onChange={handleVariantChange}
                            name="number"
                            className={name === selectedNumber ? 'w-5 h-5 text-lg text-orange border-none ring-diamond-80 ring-2 ring-offset-2 focus:outine-none focus:ring-diamond-80 focus:outline-offset-0' : 'w-5 h-5 text-lg bg-diamond-10 text-orange border-none focus:outine-none focus:ring-diamond-80 focus:outline-offset-0'}
                          />
                          {name}
                        </label>
                      ))}
                    </div>
                  </div>
                </div>
              }
            </div>
          </div>
        </div>
      </div>

      <div className="w-full pb-24">
        <div className="w-10/12 mx-auto max-w-screen-xl">
          <div className="flex-none md:flex justify-between gap-12">
            <div className="w-full md:w-2/12">
              <div className="bg-diamond rounded-full w-36 h-36 flex items-center justify-center">
                <img src="/img/cart_duo_white.png" className="w-24 h-24" alt="" />
              </div>
            </div>
            <div className="w-full md:w-10/12 pt-6 md:pt-0">

              <div className="flex items-center justify-between pb-6">
                <h2 className="font-poppins font-semibold text-2xl md:text-3xl text-orange">Add to cart</h2>
              </div>

              <div className="max-w-none prose font-poppins prose-p:text-diamond-80 prose-h2:font-semibold prose-h2:text-diamond prose-h2:mb-3 prose-p:font-medium leading-snug mb-12">
                <h2>Please check your selection</h2>
                <PrismicRichText linkResolver={linkResolver} field={entry.data.info.richText} />
              </div>

              {selectedVariant && (
                <div className="w-full pb-12">
                  <div className="font-poppins max-w-none font-semibold text-diamond text-4xl xl:text-6xl">
                    {selectedVariant.compareAtPriceV2 && selectedVariant.priceV2.amount < selectedVariant.compareAtPriceV2.amount ? (
                      <div className="flex flex-col lg:flex-row gap-0 lg:gap-6">
                        <div className="line-through">{currency.format(selectedVariant.compareAtPriceV2.amount)} NZD</div>
                        <div className="text-orange">{currency.format(selectedVariant.priceV2.amount)} NZD</div>
                      </div>
                    ) : (
                      <>
                        {currency.format(selectedVariant.priceV2.amount)} NZD
                      </>
                    )}
                  </div>
                </div>
              )}

              {!!selectedVariant && (
              <div className="w-full">
                <ProductQuantity formValues={formValues} variantId={selectedVariant.id} customSelections={customSelections} />
              </div>
              )}
            </div>
          </div>
        </div>
      </div>

      <ProductSafety data={entry.data} />
      <Footer />
    </>
  )
}

export const query = graphql`
query {
  prismicXmasDecorations {
    data {
      out_of_stock {
        component
        name
      }
      page_title
      meta_description
      heading
      tag
      description {
        richText
      }
      images {
        image {
          alt
          gatsbyImageData
        }
      }
      safety_and_care_image {
        alt
        gatsbyImageData
      }
      safety_and_care {
        richText
      }
      info {
        richText
      }
      shape {
        name
        image {
          alt
          gatsbyImageData
        }
      }
      shape_text {
        richText
      }
      colour {
        image {
          alt
          gatsbyImageData
        }
        name
        hex_code
      }
      material_text {
        richText
      }
      number_text {
        richText
      }
    }
  }
  shopifyProduct(handle: {eq: "christmas-decorations"}) {
    id
    priceRangeV2 {
      maxVariantPrice {
        amount
      }
    }
    shopifyId
    storefrontId
    status
    variants {
      id
      price
      shopifyId
      storefrontId
      title
      availableForSale
    }
    options {
      id
      name
      values
    }
  }
}
`

export default ChristmasDecorations
